<template>
  <div class="container">
    <keep-alive>
      <headers></headers>
    </keep-alive>

    <right></right>
    <scrollpage></scrollpage>

    <div class="w box_sizing width_box">
      <div class="left_position">
        <left></left>
      </div>
      <div class="index_wrap box_sizing">
        <el-carousel height="375px;">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <div class="banner" @click="jumpBannerDetail(item.id)">
              <img :src="item.image" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <div class="menu_box box_sizing flex_box">
          <div
            class="menu_list box_sizing flex_box"
            v-for="(item, index) in menuList"
            :key="'menu' + index"
            :style="
              'background:url(' +
              item.url +
              ') no-repeat left top;background-size: 100% 100%;-webkit-background-size: 100% 100%;'
            "
          >
            <div class="menu_list_img">
              <img :src="item.menu" alt="" />
            </div>
            <div
              class="menu_list_right flex_1 box_sizing flex_box flex_justify_between flex_diection"
            >
              <div class="menu_list_title">
                {{ item.title }}<span class="menu_list_title_line"></span>
              </div>
              <div
                class="menu_list_bottom flex_box flex_justify_between flex_align_center"
              >
                <div class="menu_list_type">{{ item.type }}</div>
                <div class="menu_list_go">GO &rsaquo;</div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="index_list" style="margin-top: 40px">
          <div class="index_list_top">
            <div class="index_list_top_title">PRACTICE</div>
            <div class="index_list_top_txt">练习平台</div>
          </div>

          <div class="index_lx_box box_sizing flex_box">
            <div
              class="index_lx_list"
              v-for="(item, index) in lxList"
              :key="'lx' + index"
              @click="totypeDetail(item.id)"
            >
              <div class="index_lx_img">
                <img :src="item.url" alt="" />
              </div>
              <div class="index_lx_bottom overflow1">{{ item.title }}</div>
            </div>
          </div>
        </div>

<!--        <div class="index_list">-->
<!--          <div class="index_list_top">-->
<!--            <div class="index_list_top_title">MOST POPULAR COURSES</div>-->
<!--            <div class="index_list_top_txt">热门课程</div>-->
<!--          </div>-->

<!--          <div class="course_box box_sizing flex_box flex-warp">-->
<!--            <div-->
<!--              class="course_list flex_box flex_diection box_sizing"-->
<!--              v-for="(item, index) in courseList"-->
<!--              :key="'course' + index"-->
<!--              :style="-->
<!--                'background:url(' +-->
<!--                item.image +-->
<!--                ') no-repeat left top;background-size: 100% 100%;-webkit-background-size: 100% 100%;'-->
<!--              "-->
<!--              @click="jumpDetail(item.id)"-->
<!--            >-->
<!--              <div class="course_list_title black_shadow">{{ item.name }}</div>-->
<!--              <div class="course_list_txt flex_1 overflow2 black_shadow">-->
<!--                {{ item.describe }}-->
<!--              </div>-->
<!--              <div class="course_list_btn">了解更多</div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="index_list">-->
<!--          <div class="index_list_top flex_box">-->
<!--            <div class="index_list_top_left flex_1">-->
<!--              <div class="index_list_top_title">PTE STRATEGY</div>-->
<!--              <div class="index_list_top_txt">PTE攻略</div>-->
<!--            </div>-->
<!--            &lt;!&ndash; <div class="index_list_top_right">查看更多 &rsaquo;</div> &ndash;&gt;-->
<!--          </div>-->

<!--          <div class="questions_box box_sizing flex_box flex-warp">-->
<!--            <div-->
<!--              class="questions_list flex_box box_sizing flex_align_center"-->
<!--              v-for="(item, index) in strategyList"-->
<!--              :key="'course' + index"-->
<!--            >-->
<!--              <div class="questions_list_img">-->
<!--                <img :src="item.image" alt="" />-->
<!--              </div>-->
<!--              <div class="questions_list_right flex_1">-->
<!--                <div class="questions_list_title">-->
<!--                  {{ item.title }}-->
<!--                </div>-->
<!--                <div class="questions_list_txt overflow1">-->
<!--                  {{ item.describe }}-->
<!--                </div>-->
<!--                <div class="questions_list_btn" @click="jumpStrategy(item.id)">-->
<!--                  查看详情-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>

    <!-- <footers></footers> -->
  </div>
</template>

<script>
// @ is an alias to /src
import headers from "@/components/headers";
// import footers from "@/components/footders";
import left from "@/components/left";
import right from "@/components/right";
import scrollpage from "@/components/scrollpage";
// right,
// footers,

import "@/css/index.css";
var _this;
export default {
  name: "Home",
  components: {
    headers,
    left,
    right,
    scrollpage,
  },
  data() {
    return {
      loginStatus: false,
      loginWx: false,
      mobile: "",
      code: "",
      num: 0,
      status: false,
      courseList: [],
      lxList: [
        {
          id:1,
          url: require("../assets/images/lx0.png"),
          title: "SPEAKING",
        },
        {
          id:2,
          url: require("../assets/images/lx1.png"),
          title: "WRITING",
        },
        {
          id:3,
          url: require("../assets/images/lx2.png"),
          title: "READING",
        },
        {
          id:4,
          url: require("../assets/images/lx3.png"),
          title: "LISTENING",
        },
      ],
      menuList: [
        {
          url: require("../assets/images/menu0.png"),
          title: "PERFORMANCE ANALYSIS",
          type: "成绩分析",
          menu: require("../assets/images/menu_img0.png"),
        },
        {
          url: require("../assets/images/menu1.png"),
          title: "WORD BOOKS",
          type: "单词本",
          menu: require("../assets/images/menu_img1.png"),
        },
        {
          url: require("../assets/images/menu2.png"),
          title: "WORD BOOKS",
          type: "口语分提前测",
          menu: require("../assets/images/menu_img2.png"),
        },
        {
          url: require("../assets/images/menu3.png"),
          title: "MEMBER RECHARGE",
          type: "会员充值",
          menu: require("../assets/images/menu_img3.png"),
        },
      ],
      bannerList: [],
      strategyList: [],
    };
  },
  created() {
    _this = this;
    _this.getCouseList();
    _this.getBanner();
    _this.getStrategy();
  },
  // mounted: {},
  methods: {
    // 跳转题目类型

    totypeDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/practice",
        query: {
          id: id,
        },
      });
    },

    // 跳转攻略详情
    jumpStrategy(id) {
      console.log(id);
      this.$router.push({
        path: "/strategyDetail",
        query: {
          id: id,
        },
      });
    },

    // 获取PTE攻略
    getStrategy() {
      _this.$axios
        .post("faq/getList", {})
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.strategyList = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 跳转轮播图详情
    jumpBannerDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/bannerDetail",
        query: {
          id: id,
        },
      });
    },

    // 获取banner
    getBanner() {
      _this.$axios
        .post("banner/getList", {})
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.bannerList = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 获取课程列表
    getCouseList() {
      _this.$axios
        .post("course.course/getCourseList", {})
        .then((res) => {
          console.log(res);
          if (res.code == 1) {
            _this.courseList = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 课程跳转详情
    jumpDetail(id) {
      console.log(id);
      this.$router.push({
        path: "/couseDetail",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.black_shadow{
   text-shadow:4px 2px 10px black;
}
</style>


